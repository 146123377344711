import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Dexie, Table } from 'dexie';

export interface Queue {
    id?: number;
    user_id?: number;
    uuid?: string;
    reference_table: string;
    reference_id?: number;
    reference?: string;
    content?: string;
    payload?: string;
    attempts?: number;
    attempt_at?: Date;
    attempt_error?: string | {message: string, status: number, error: boolean};
    created_at?: Date;
}

class QueueDatabase extends Dexie {
    queues!: Table<Queue, number>;
    queues_faileds!: Table<Queue, number>;

    constructor() {
        super('fc-database');
        this.version(2).stores({
            queues: '++id,uuid,created_at',
            queues_faileds: '++id,uuid,created_at',
        });
    }
}

@Injectable({
    providedIn: 'root',
})
export class QueuesDBService {

    private db: QueueDatabase;

    constructor() {
        this.db = new QueueDatabase();
    }

    async add(queue: Queue): Promise<void> {
        if (!queue.uuid) {
            queue.uuid = uuidv4();
        }
        if (!queue.user_id) {
            queue.user_id = 1;
        }
        if (!queue.created_at) {
            queue.created_at = new Date();
        }
        await this.db.queues.add(queue);
    }

    async all(table = 'queues'): Promise<Queue[]> {
        if (table === 'queues') {
            return this.db.queues.toArray();
        } else {
            return this.db.queues_faileds.toArray();
        }
    }

    async get(id: number): Promise<Queue | undefined> {
        return this.db.queues.get(id);
    }

    async getByUuid(uuid: string): Promise<Queue | undefined> {
        return this.db.queues.where('uuid').equals(uuid).first();
    }

    async getNewestQueue(table = 'queues'): Promise<Queue | undefined> {
        if (table === 'queues') {
            return this.db.queues.orderBy('created_at').last();
        } else {
            return this.db.queues_faileds.orderBy('created_at').last();
        }
    }

    async getOldestQueue(table = 'queues'): Promise<Queue | undefined> {
        if (table === 'queues') {
            return this.db.queues.orderBy('created_at').first();
        } else {
            return this.db.queues_faileds.orderBy('created_at').first();
        }
    }

    async getByFields(fields: { [key: string]: string | number }, firstOnly: boolean = true): Promise<Queue[] | Queue | undefined> {

        // Iniciamos a query
        let collection = this.db.queues.toCollection();

        // Aplicamos cada filtro
        for (const field in fields) {
            if (fields.hasOwnProperty(field)) {
                const value = fields[field];
                // Fazemos um filtro em memória para múltiplos campos
                collection = collection.filter(item => item[field] === value);
            }
        }

        // Ordenamos e retornamos conforme solicitado
        if (firstOnly) {
            return collection.sortBy('created_at').then(items => items[0]);
        } else {
            return collection.sortBy('created_at');
        }
    }

    async put(queue: Queue): Promise<void> {
        await this.db.queues.put(queue);
    }

    async delete(id: number): Promise<void> {
        await this.db.queues.delete(id);
    }

    async addFailed(queue: Queue): Promise<void> {
        console.log('addFailed', queue);
        await this.db.queues_faileds.add(queue);
    }

    async deleteOldFailedQueues(): Promise<void> {
        const dateLimit = new Date();
        dateLimit.setDate(dateLimit.getDate() - 30);

        const keysToDelete = await this.db.queues_faileds
            .where('created_at')
            .below(dateLimit)
            .primaryKeys();

        await this.db.queues_faileds.bulkDelete(keysToDelete);
    }
}
